<template>
  <div>
    <div class="inner_head">
      <img class="head_img" src="../../statics/images/company/xinli_adv.jpg" alt="">
    </div>
    <div class="current_menu">
      <div class="content">
        <div class="page_indicate">
          <div class="img" />
          <span>企业党建<span> > </span>{{ menuTitle }}</span>
        </div>
        <ul class="page_menu">
          <li
            v-for="(item,index) in menuList"
            :key="index"
            :class="{'active': index === menuActive}"
            @click="menuSelect(item,index)"
          >
            {{ item.name }}
            <div class="menu_block" />
          </li>
        </ul>
      </div>
    </div>
    <div v-for="(item,index) in contentList" :key="index">
      <div v-if="item.flag === '13'" class="news">
        <ul v-if="archieveList.length">
          <li v-for="(aItem,aIndex) in archieveList" :key="aIndex" @click="detailArchieve(aItem,157)">
            <img v-if="aItem.iconUrl" :src="baseUrl + aItem.iconUrl" alt="">
            <img v-else src="../../statics/images/s_logo.png" alt="">
            <div class="info">
              <div class="head">
                <p class="title">{{ aItem.title }}</p>
                <p v-if="aItem.publishDate" class="date">{{ aItem.publishDate.slice(0, 10) }}</p>
              </div>
              <p class="remark">{{ aItem.remark }}</p>
            </div>
          </li>
        </ul>
        <div v-else class="no_info">暂无数据</div>
        <div v-if="archieveList.length" class="pagination_con">
          <el-pagination background layout="prev, pager, next" :current-page="news.currentPage" :total="news.total" @current-change="newsCurrentChange" />
        </div>
      </div>
      <div v-if="item.flag === '9'" class="programme">
        <ul v-if="programmerList.length">
          <li v-for="(pItem,pIndex) in programmerList" :key="pIndex" @click="soluteDetail(item)">
            <img :src="baseUrl + pItem.iconUrl" alt="">
            <div class="word">
              <p class="title">{{ pItem.title }}</p>
              <p class="text">{{ pItem.remark }}</p>
            </div>
          </li>
        </ul>
        <div v-else class="no_info">暂无信息</div>
      </div>
    </div>
  </div>
</template>
<script>
import { getMenu, getMainContent, getArchiveListByMenuId } from '@/plugin/api.js'

export default ({
  data() {
    return {
      menuTitle: '',
      menuList: [],
      archieveList: [],
      contentList: [],
      news: {
        total: 0,
        currentPage: 1
      },
      baseUrl: '/website',
      programmerList: []

    }
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal) this.routeChange()
      }
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    detailArchieve(item, num) {
      const routeData = this.$router.resolve({
        path: '/archieveDetail', query: { menuId: num, id: item.id }
      })
      window.open(routeData.href, '_blank')
      // this.$router.push({ path: '/archieveDetail', query: { menuId: num, id: item.id }})
    },
    routeChange() {
      window.scrollTo({
        top: 0
      })
      const url = this.$route.query.url ? this.$route.query.url : '151'
      const urlType = this.$route.query.urlType
      const num = this.menuList.findIndex(item => {
        return item.url === url
      })
      this.menuActive = num < 0 ? 0 : num
      this.menuTitle = this.menuList[this.menuActive].name

      this.getInfo(url, urlType)
    },
    menuSelect(item, index) {
      this.menuActive = index
      this.menuTitle = item.name
      this.$router.push({ path: '/enterpriseBuilding', query: { url: item.url, urlType: item.urlType }})
    },
    init() {
      getMenu({ tenantId: 'xljr' }).then(res => {
        const menu = res.data.data.menuList.find(item => {
          return item.id === 156
        })
        this.menuList = menu.children
        this.routeChange()
      })
    },
    newsCurrentChange(number) {
      this.news.currentPage = number
      getArchiveListByMenuId({ tenantId: 'xljr', menuId: 157, pageNum: this.news.currentPage, pageSize: 10 }).then(res => {
        this.archieveList = res.data.data.archiveList
        window.scrollTo(0, 0)
      })
    },
    getInfo(url, urlType) {
      getMainContent({ tenantId: 'xljr', url, urlType }).then(res => {
        const obj = res.data.data.contentMainList[0]
        this.contentList = [obj]
        if (obj.id === 152) {
          this.programmerList = obj.contentItemTreeList
        }
        if (obj.id === 151) {
          getArchiveListByMenuId({ tenantId: 'xljr', menuId: 157, pageNum: this.news.currentPage, pageSize: 10 }).then(res => {
            this.archieveList = res.data.data.archiveList
            this.news.total = res.data.data.total
          })
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.programme{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ul{
    width: 1400px;
    margin: 50px auto;
    display: flex;
    flex-wrap: wrap;
    li{
      cursor: pointer;
      display: flex;
      border: 1px solid #E8E8E8;
      margin: 0 20px 40px 0;
      &:nth-child(even){
        margin-right: 0;
      }
      img{
        width: 320px;
        height: 190px;
      }
      .word{
        margin: 0 30px;
        .title{
          color: #000000;
          font-size: 18px;
          padding: 8px 0;
          border-bottom: 1px solid #BBBBBB;
          margin-top: 20px;
          margin-bottom: 20px;
        }
        .text{
          width: 300px;
          color: #101010;
          font-size: 14px;
          text-align: justify;
          word-break: break-all;
          overflow: hidden;
          display: -webkit-box;
          -webkit-line-clamp: 5;
          -webkit-box-orient: vertical;
        }
      }
    }
  }
}
// 屏幕大于 1024px 或小于 1440px 时应用该样式
@media screen and (max-width: 1440px) {
  .programme{
    ul{
      width: 1200px;
      li{
        margin: 0 15px 40px 0;
        img{
          width: 280px;
        }
        .word{
          margin: 0 20px;
          .text{
            width: 270px;
          }
        }
      }
    }
  }
}
.pagination_con{
  display: flex;
  margin: 20px 0;
  .el-pagination{
    margin: 0 auto;
  }
}
.introduction{
  background: #fff;
}
.news{
  width: 1200px;
  margin: 0 auto;
  ul{
    padding: 20px 0;
    li{
      cursor: pointer;
      background-color: #fff;
      display: flex;
      padding: 25px;
      margin-bottom: 25px;
      img{
        width: 200px;
        height: 120px;
        background-color: #333;
        margin-right: 30px;
        flex-shrink: 0;
      }
      .info{
        width: 100%;
      }
      .head{
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        .title{
          font-size: 20px;
          color: #000;
        }
        .date{
          color: #999;
          font-size: 18px;
        }
      }
      .remark{
        color: #999;
        font-size: 18px;
      }
    }
  }
}
</style>
